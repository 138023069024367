<template>
  <section class="mfo__banner">
    <picture class="mfo__banner-img">
      <source
          media="(max-width: 768px)"
          srcset="
            @/assets/magento/magento-services-integration/magento-services-integration_small.webp    1x,
            @/assets/magento/magento-services-integration/magento-services-integration_small@2x.webp 2x
          "
      />
      <source
          media="(max-width: 768px)"
          srcset="
            @/assets/magento/magento-services-integration/magento-services-integration_small.jpg    1x,
            @/assets/magento/magento-services-integration/magento-services-integration_small@2x.jpg 2x
          "
      />

      <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/magento/magento-services-integration/magento-services-integration_medium.webp    1x,
            @/assets/magento/magento-services-integration/magento-services-integration_medium@2x.webp 2x
          "
      />
      <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/magento/magento-services-integration/magento-services-integration_medium.jpg    1x,
            @/assets/magento/magento-services-integration/magento-services-integration_medium@2x.jpg 2x
          "
      />

      <source
          srcset="
            @/assets/magento/magento-services-integration/magento-services-integration.webp    1x,
            @/assets/magento/magento-services-integration/magento-services-integration@2x.webp 2x
          "
      />
      <source
          srcset="
            @/assets/magento/magento-services-integration/magento-services-integration.jpg    1x,
            @/assets/magento/magento-services-integration/magento-services-integration@2x.jpg 2x
          "
      />

      <img
          src="@/assets/magento/magento-services-integration/magento-services-integration.jpg"
          alt="Third party services integration"
          loading="lazy"
          width="1920"
          height="640"
      />
    </picture>
    <h1 class="mfo__banner-title">
      Third Party Services Integration
    </h1>
  </section>

  <section-layout name="mfo__first" title="" margin="true">
    <p>Are you looking to enhance the efficiency of your Magento webstore by implementing the integration of 3rd Party Services? The WDevs team can connect your third-party business environments to the existing Magento platform. Whether it’s a ready-made solution or customized ERP system integration, we provide full synchronization & support. We also help you seamlessly automate various business processes to simplify your eCommerce sales channel management.</p>
    <p>We offer a comprehensive integration of your 3rd party solution with Magento, which consists of the following 4 stages:</p>
  </section-layout>

  <section-layout name="mfo__connection" title="Connection (receiving data from the ERP system)" margin="true">
    <p>Our many years of experience in integration with leading ERP systems, such as NetSuite, Merlin, Microsoft Dynamics, Salesforce, as well as a few  lesser-known API-based systems, allows us the technical scope to develop and create a connector suitable for your specific needs, or adapt an existing connector to a specific ERP system.</p>
  </section-layout>

  <section-layout name="mfo__mapping" title="Mapping ERP data with Magento or other eCommerce systems" margin="true">
    <p>Data mapping is limited only by the capabilities of the developer, as well as the  manufacturer of a particular solution, therefore setting up automatic mapping involves the crucial step of customizing the ERP integration solution to meet the needs of your business. The WDevs  team, together with our long-trusted partner - firebearstudio.com - has developed a unique innovative solution that uses our partners' synchronization module, and involves the creation and customization of a visualized data mapping process.</p>
  </section-layout>

  <section-layout name="mfo__customization" title="Customization" margin="true">
    <p>In collaboration with you, we will  build  a data structure with  all the necessary attributes of  the product object in the ERP system (3rd party solution), and the eCommerce system for further synchronization.</p>
  </section-layout>

  <section-layout name="mfo__synchronization" title="Synchronization" margin="true">
    <p>The decision on the type of integration is always made collaboratively  with the client, based on their specific case and subsequent needs. Find more information in our article about <a href="/blog/erp">ERP integration with Magento</a>.</p>
    <p>A comprehensive 3rd Party Systems integration with an eCommerce solution will benefit your business by automating your sales and product management processes, and by gathering all your data in one place. You can increase the workflow of nearly every department in your company, improve on collaborations and simplify the way your team members perform daily tasks.</p>
    <p>Our exclusive integration solution for Magento websites offers real-time smooth synchronization with various 3rd Party solutions - sales & marketing tools, delivery & shipping, store & warehouses, accounting & payment systems, and so much  more.</p>
    <a href="#" @click.prevent="goBack()" class="button primary back">Back to Magento</a>
  </section-layout>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";

export default {
  data(){
    return{
      position:null,
    }
  },
  components: {
    SectionLayout,
  },
  methods:{
    goBack(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/magento` }})
        this.$router.push('/magento')
      }else{
        this.$router.go(-1)
      }
    }
  },
  created(){
    window.history.scrollRestoration = "auto";
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.mfo {
  &__banner {
    height: 300px;
    position: relative;
    @include media(">=tablet") {
      height: 500px;
    }
    @include media(">=desktop") {
      height: 640px;
      margin-bottom: 60px;
    }
    &-img {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
      bottom: 40px;
      font-size: 40px;
      left: 40px;
      max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
      bottom: 80px;
      left: 40px;
      font-size: 56px;
      max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
      bottom: 80px;
      left: 80px;
      font-size: 72px;
      max-width: 1150px;
    }
  }

  &__first,
  &__connection,
  &__mapping,
  &__customization,
  &__synchronization {
    .section_content {
      display: block;
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
    a {
      color: $red;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .button{
      cursor: pointer;
      margin-top:40px;
      color:$white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      padding: 10px 24px;
      border-radius:26px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>